<!--<mat-form-field #formField  class="wabel-bo-input" [color]="color" [appearance]="appearance" [class.filled]="appearance === 'fill'" [class.no-hint]="!hint" [class.has-errors]="invalid && touched">-->
<!--  <mat-label *ngIf="label">{{label}}</mat-label>-->

  <div class="preview" *ngIf="showPreview">
    <div *ngIf="fileUrl; else noFile">
      <img *ngIf="predefinedType === 'image'; else file" [src]="fileUrl" />
      <ng-template #file>
        <a [href]="fileUrl" target="_blank">See file</a>
      </ng-template>
    </div>
    <ng-template #noFile>{{placeholder || "No file uploaded"}}</ng-template>
  </div>

  <mat-file-upload
    [labelText]="label"
    [selectButtonText]="selectText"
    [uploadButtonText]="uploadText"
    [allowMultipleFiles]="multiple"
    [showUploadButton]="showUploadButton"
    [acceptedTypes]="acceptedTypes"
    (uploadClicked)="validate()"
    (selectedFilesChanged)="selectFile($event)"
  >
  </mat-file-upload>

<!--  <wbo-wrapper-suffix matSuffix [touched]="touched" [invalid]="invalid" [unchanged]="unchanged" [required]="required" [dirty]="dirty" [empty]="empty" [saveAction]="saveAction" [clearAction]="clearAction" [historyAction]="historyAction" (cancelEvent)="cancel()" (validateEvent)="validate()" (clearEvent)="clear()">-->
<!--  </wbo-wrapper-suffix>-->

<!--  <mat-error *ngIf="touched && invalid">-->
<!--    <ng-container *ngIf="required">This field cannot be empty.</ng-container>-->
<!--  </mat-error>-->

  <div class="file-upload-hint">
    <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
  </div>
<!--</mat-form-field>-->
